import React from 'react';

export default function Reservation() {

    return (
        <div
            className="appMountPoint-asideBooking"
            data-hotel_id={"36935"}
            data-room_id={"65304"}
            data-source={"customer"}
        />
    );
}
